import React, { useState } from "react"

import Agents from 'components/singleListing/agents'
import ApplyOnlineButton from "../../images/apply-online-button.png"

import moment from 'moment'
import { format24HrTime } from 'utils'

export default ({
    listing,
    people,
    pageContext,
    auction,
    privateSaleDate
}) => {
    const {
        bathrooms,
        bedrooms,
        carport_spaces,
        garage_spaces,
        off_street_spaces,
        type,
        price_text,
        opentimes,
        agent_id_1,
        agent_id_2,
        status,
        show_sold_price,
        sold_price,
        xml_id,
        photos,
    } = listing

    const _photos = Object.entries(photos)

    const firstPhoto = _photos[0] ? _photos[0][1].versions.large.url : ""

    const [linkCopied, setLinkCopied] = useState(false)

    const currentUrl = typeof window !== 'undefined' ? window.location.href : ''

    const copyToClipboard = () => {
        navigator.clipboard.writeText(currentUrl)

        setLinkCopied(true)

        setTimeout(() => {
            setLinkCopied(false)
        }, 2000)
    }

    const getDocuments = () => {
        const documents = [
            {
                key: 'brochures',
                name: 'Brochure'
            }, {
                key: 'floorplans',
                name: 'Floor Plan'
            }
        ]

        const response = []

        documents.forEach(document => {
            let {
                key,
                name,
            } = document

            const keyObjects = Object.keys(listing[key])

            if (keyObjects.length) {
                const object = listing[key][keyObjects[0]]

                name = object.description ? object.description : name

                response.push({
                    name,
                    object
                })
            }
        })

        return response
    }

    const allAgents = people.nodes
    const agentIds = [agent_id_1, agent_id_2]

    const agents = allAgents.filter(({ peopleFields }) => {
        return agentIds.indexOf(peopleFields.agentpointId) !== -1
    })

    const showAuctionDetails = auction && moment(auction.date).isAfter(moment().startOf('day'))

    const reversedOpenTimes = opentimes ? [...opentimes].reverse() : []

    return (

        <div className="singleListing-details">
            {privateSaleDate &&
                <>
                    <h4 className={'red'}>
                        <i className={'fa fa-home'} />
                        Private sale - offers close:
                    </h4>
                    <div>
                        {privateSaleDate} (unless sold prior)
                    </div>
                </>
            }
            {showAuctionDetails &&
                <>
                    <h4 className={'red'}>
                        <i className={'fa fa-gavel'} />
                        Auction date
                    </h4>
                    <div>
                        {moment(auction.date + ' ' + auction.time).format('Do MMMM, YYYY [at] hh:mm A')}
                    </div>
                </>
            }
            {status === 2 ?
                <>
                    <h4 dangerouslySetInnerHTML={{ __html: 'Sold' }} />
                    <div dangerouslySetInnerHTML={{ __html: (show_sold_price && parseInt(show_sold_price)) ? '$' + sold_price.toLocaleString() : '(Undisclosed price)' }} />
                </>
                : status === 5 ?
                    <>
                        <h4 dangerouslySetInnerHTML={{ __html: 'Under Offer' }} />
                        <div dangerouslySetInnerHTML={{ __html: price_text }} />
                    </>
                    :
                    <>
                        <h4 dangerouslySetInnerHTML={{ __html: type === "ResidentialSale" ? 'Selling Price' : 'Price' }} />
                        <div dangerouslySetInnerHTML={{ __html: price_text }} />
                        <h4 id="inspections" dangerouslySetInnerHTML={{ __html: 'Inspection' }} />
                        {!reversedOpenTimes.length ?
                            <div dangerouslySetInnerHTML={{ __html: 'by appointment contact agent' }} />
                            :
                            <ul className="open-times">
                                {reversedOpenTimes.map(time => {
                                    const {
                                        id,
                                        date,
                                        start_time,
                                        end_time
                                    } = time

                                    return (
                                        <li key={id}>
                                            {moment(date).format('Do MMMM, YYYY')}
                                            <span>
                                                {format24HrTime(start_time)} - {format24HrTime(end_time)}
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </>
            }
            {listing.type === 'ResidentialLease' && listing.status === 1 ?
                <>
                    {xml_id &&
                        <div className={'inspect-apply'}>
                            <a 
                                href={`https://book.inspectrealestate.com.au/RegisterNew?agentID=AB-WILLIAMHUXLEY&uniqueID=${xml_id}&imgURL=${firstPhoto}&Type=rental`} 
                                target="_blank" 
                                className="button bookInspection"
                            >
                                    Book Inspection
                            </a>
                            <a
                                href={`https://2apply.com.au/Form?agentID=AB-WILLIAMHUXLEY&uniqueID=${xml_id}`}
                                target="_blank"
                                className="button bookInspection"
                            >
                                Apply online
                            </a>
                        </div>
                    }
                </>
            : null}
            <h4 
                id="share" 
                dangerouslySetInnerHTML={{ __html: 'Share Property' }} 
            />
            <ul>
                <li>
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>
                            Facebook
                        </span>
                        <svg
                            role="img"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>
                                Facebook icon
                            </title>
                            <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" /
                            ></svg>
                    </a>
                </li>
                <li>
                    <a
                        href={`mailto:?&subject=${pageContext.address}&body=${currentUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>Email</span>
                        <svg
                            role="img"
                            viewBox="0 0 18 18"
                        >
                            <title>Email</title>
                            <rect id="Canvas" fill="#ff13dc" opacity="0" />
                            <path d="M9,10.094l9-6.7615v-.75A.5735.5735,0,0,0,17.4375,2H.5625A.57351.57351,0,0,0,0,2.5835V3.318Z" />
                            <polygon points="12.389 8.981 18 13.184 18 6.667 18 4.771 12.389 8.981" />
                            <path d="M11.433,9.7,9.645,11.047a1.086,1.086,0,0,1-1.29,0L6.541,9.6875,0,14.534v.883A.573.573,0,0,0,.5625,16h16.875A.573.573,0,0,0,18,15.417v-.795Z" />
                            <polygon points="5.582 8.969 0 4.756 0 6.667 0 13.097 5.582 8.969" />
                        </svg>
                    </a>
                </li>
                <li>
                    <button onClick={copyToClipboard}>
                        <span
                            dangerouslySetInnerHTML={{ __html: linkCopied ? 'Link Copied!' : 'Copy Link' }}
                        />
                        <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                        >
                            <title>Link</title>
                            <rect
                                id="Canvas"
                                fill="#ff13dc"
                                opacity="0"
                            />
                            <path d="M15.85,2.15a3.588,3.588,0,0,0-5.074,0c-.1925.193-2.132,2.111-2.6755,2.6545A4.15349,4.15349,0,0,1,9.972,5.108c.2595-.26,1.784-1.763,1.8915-1.8705a2.05061,2.05061,0,1,1,2.9,2.9L11.204,9.695a2.30853,2.30853,0,0,1-1.686.65,1.97648,1.97648,0,0,1-1.35-.5545,2.07708,2.07708,0,0,1-.6205-.813,1.03342,1.03342,0,0,0-.214.159l-.8175.856a3.57187,3.57187,0,0,0,.613.8365,3.92429,3.92429,0,0,0,5.3385-.219L15.85,7.226a3.587,3.587,0,0,0,.00322-5.07278Z" />
                            <path d="M7.963,12.912c-.26.26-1.75,1.7735-1.8565,1.881a2.05061,2.05061,0,0,1-2.9-2.9L6.8,8.3a2.29,2.29,0,0,1,1.683-.646,2.1,2.1,0,0,1,1.892,1.391,1.03342,1.03342,0,0,0,.214-.159l.867-.8605a3.58269,3.58269,0,0,0-.613-.8365,3.6555,3.6555,0,0,0-5.13.024L2.1195,10.806a3.588,3.588,0,1,0,5.074,5.0745c.193-.193,2.097-2.1215,2.6405-2.665A4.15006,4.15006,0,0,1,7.963,12.912Z" />
                        </svg>
                    </button>
                </li>
            </ul>
            {getDocuments().length ?
                <>
                    <h4 dangerouslySetInnerHTML={{ __html: 'Download' }} />
                    <div className={"singleListing-downloads singleListing-downloads" + getDocuments().length}>
                        {getDocuments().map(document => (
                            <a
                                key={document.name}
                                href={document.object.versions.large.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button black"
                                dangerouslySetInnerHTML={{ __html: document.name }}
                            />
                        ))}
                    </div>
                </>
                : ''}
            <Agents
                agents={agents}
            />
        </div>
    )
}