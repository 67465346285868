import React from "react"

import SEO from "components/seo"
import { graphql } from "gatsby"

import Related from 'components/singleListing/related'
import Hero from 'components/singleListing/hero'
import Sidebar from 'components/singleListing/sidebar'
import { Link } from "react-scroll"

import { nl2br } from 'utils'

export default ({ pageContext, data }) => {
    const { allProperties } = pageContext

    const {
        listing: _listing,
        people
    } = data.siteFields

    const listing = JSON.parse(_listing.dataJSON)

    const allAgents = people.nodes
    const agentIds = [listing.agent_id_1]

    const agents = allAgents.filter(({ peopleFields }) => {
        return agentIds.indexOf(peopleFields.agentpointId) !== -1
    })

    const {
        auction_date,
        auction_place,
        auction_time,
        status,
        address,
        type,
        bathrooms,
        bedrooms,
        carport_spaces,
        garage_spaces,
        off_street_spaces,
        description,
        features,
        headline,
        photos,
        video_url,
    } = listing

    let _description = description

    const privateSaleDate = (() => {
        let response = null

        const firstvariableTypes = ["Private Sale, Offers Closing on the", "Private Sale, Offers Closing On The"]

        firstvariableTypes.forEach(type => {
            if(response){
                return
            }

            const secondvariable = "(Unless Sold Prior)"
            const regExString = new RegExp("(?:"+type+")(.[\\s\\S]*)(?:"+secondvariable+")", "g")
            const testRE = regExString.exec(description)
    
            if(testRE && testRE[0]){
                _description = _description.replace(testRE[0]+')\n\n', '')
                _description = _description.replace(testRE[0]+').\n\n', '')
            }

            const _response = testRE && testRE[1] ? testRE[1].replace(' (', '') : null

            if(_response){
                response = _response
            }
        })

        return response
    })()

    return (
        <>
            <SEO title={address.street_number + ' ' + address.street_name + ' ' + address.suburb} />
            <div className="singleListing">
                <Hero
                    photos={photos}
                    address={address.street_number + ' ' + address.street_name + ' ' + address.suburb}
                    type={type}
                    beds={bedrooms}
                    baths={bathrooms}
                    cars={carport_spaces + garage_spaces + off_street_spaces}
                    video={video_url}
                    status={status}
                />
                <div className="singleListing-mobileButtons">
                    {agents.length ?
                        <>
                            <a href={'mailto:' + agents[0].peopleFields.emailAddress}>
                                <i className="fe fe-mail" />
                                Email
                            </a>
                            <a href={'tel:' + agents[0].peopleFields.mobileNumber}>
                                <i className="fe fe-phone" />
                                Call
                            </a>
                        </>
                        : ''}
                    <Link
                        className="inspections"
                        to="inspections"
                        spy={true}
                        smooth={true}
                        offset={-68}
                        duration={500}
                    >
                        <i className="fe fe-calendar" />
                        Inspections
                    </Link>
                    <Link
                        className="share"
                        to="share"
                        spy={true}
                        smooth={true}
                        offset={-68}
                        duration={500}
                    >
                        <i className="fe fe-share" />
                        Share
                    </Link>
                </div>
                <div className="singleListing-main">
                    <div className="container">
                        <div className="singleListing-text">
                            <h1 dangerouslySetInnerHTML={{ __html: headline }} />
                            <div className={'description'} dangerouslySetInnerHTML={{ __html: nl2br(_description) }} />
                            {features.length ?
                                <>
                                    <h4 dangerouslySetInnerHTML={{ __html: 'Key Features' }} />
                                    <ul className="singleListing-features">
                                        {features.map((row, index) => (
                                            <li key={index}>
                                                { row.name}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                                : ''}
                            <div
                                className="singleListing-disclaimer"
                                dangerouslySetInnerHTML={{ __html: "The information about this property has been supplied to us by the property owner and/or third party websites, while we have no cause to doubt its accuracy, we provide no guarantee. We cannot attest to the functionality of any fixtures, fittings or inclusions to the property. Land and apartment dimensions and floor/site plans are supplied by third parties. Typing mistakes, omissions, transposing can occur, we provide to assist but make no representation. Buyers and or prospective tenants must carry out their own due diligence." }}
                            />
                        </div>
                        <Sidebar
                            listing={listing}
                            people={people}
                            pageContext={pageContext}
                            auction={(auction_date && auction_time) ? {
                                date: auction_date,
                                time: auction_time,
                                place: auction_place
                            } : null}
                            privateSaleDate={privateSaleDate}
                        />
                    </div>
                </div>
                <Related
                    allListings={allProperties.map(_property => JSON.parse(_property.dataJSON))}
                    currentListing={listing}
                />
            </div>
        </>
    )
}

export const query = graphql`
    query($id: ID!) {
        siteFields {
            listing(id: $id) {
                id
                address
                dataJSON
            }
            people {
                nodes {
                    title 
                    featuredImage {
                        mediaItemUrl
                    }
                    peopleFields {
                        position
                        phoneNumber
                        mobileNumber
                        emailAddress
                        agentpointId
                    }
                }
            }
        }
    }
`
