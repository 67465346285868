import React, { useContext, useRef } from "react"
import { MainContext } from "context/context"
import { useIsMobile } from 'utils'

import Slider from 'components/slider'

export default ({ 
    photos,
    type,
    address, 
    beds, 
    baths, 
    cars, 
    video,
    status
}) => {
	const {
        openModal
    } = useContext(MainContext)

	const isMobile = useIsMobile()

    const sliderRef = useRef(null)
    
    const _photos = Object.entries(photos)

    const handleModal = () => {
        if(isMobile){
            openModal({
                type: 'slideshow',
                data: _photos
            })
        }
    }

    const handleClickArrow = direction => {
        if(sliderRef.current){
            sliderRef.current[direction === 'left' ? 'previous' : 'next']()
        }
    }

    const videoLink = video ? video.replace('http://www.youtube.com', 'https://www.youtube.com') : ''

    const bannerContent = (() => {
        let _content = ''

        switch(status){
            case 5:
                _content = 'Under offer'
                break
            case 3:
                _content = 'Leased'
                break
            case 2:
                _content = 'Sold'
                break
        }

        return _content
    })()

    return (
        <div className="singleListing-hero">
            <div className="singleListing-hero-slideshow" onClick={handleModal}>
                { bannerContent &&
                    <div className={'banner'}>
                        { bannerContent }
                    </div>
                }
                <div className="slider-controls">
                    { ['left','right'].map(direction => (
                        <button key={direction} onClick={() => handleClickArrow(direction)}>
                            <i className={`fe fe-arrow-${ direction }`} />
                        </button>
                    ))}
                </div>
                <Slider
                    className='carousel'
                    options={{
                        wrapAround: true,
                        prevNextButtons: false,
                        groupCells: 1,
                        cellAlign: 'center',
                        pageDots: isMobile ? true : false,
                        adaptiveHeight: true
                    }}
                    flickityRef={ref => sliderRef.current = ref}
                >
                    { videoLink ?
                        <div className="carousel-cell">
                            <iframe src={ videoLink } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
                        </div>
                    : '' }

                    {_photos.map((row, index) => {
                        const {
                            url
                        } = row[1].versions.large

                        return (
                            <div 
                                className="carousel-cell" 
                                key={index} 
                            >
                                <div 
                                    className="inner" 
                                    style={{ backgroundImage: `url(${url})` }} 
                                />
                            </div>
                        )
                    })}
                </Slider>
            </div>
            <div className="singleListing-hero-details">
                <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: address }} />
                    { type === 'Commercial' ? ''
                    :
                        <div className="singleListing-bbc">
                            <div className="beds" dangerouslySetInnerHTML={{ __html: beds }} />
                            <div className="baths" dangerouslySetInnerHTML={{ __html: baths }} />
                            { cars > 0 && <div className="cars" dangerouslySetInnerHTML={{ __html: cars }} /> }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}