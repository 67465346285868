import React from "react"
import PropertyGridItem from 'components/propertyGridItem'

export default ({
    allListings,
    currentListing
}) => {

    const getBBPSString = _listing => {
        // e.g 1.1.2.1

        const {
            status,
            bathrooms,
            bedrooms,
            carport_spaces,
            garage_spaces,
            off_street_spaces
        } = _listing

        return `${ bathrooms }/${ bedrooms }/${ carport_spaces + garage_spaces + off_street_spaces }/${ status }`
    }

    const thisBBP = getBBPSString(currentListing)

    const relatedProperties = [] 

    // Shuffle 'em 
    allListings.sort(() => Math.random() - 0.5)
    
    // Same bed/bath/parking/status?
    allListings.forEach(_listing => {
        const listingBBP = getBBPSString(_listing)

        if(listingBBP === thisBBP && relatedProperties.length < 3){
            relatedProperties.push(_listing)
        }
    })

    return (
        <div className="relatedProperties">
            <section className="property-grid">
                <div className="container">
                    <h2>Similar Listings</h2>
                    <ul className="grid">
                        {relatedProperties.map((property, index) => {
                            return (
                                <PropertyGridItem
                                    key={index}
                                    property={property}
                                />
                            )
                        })}
                    </ul>
                </div>
            </section>
        </div>
    )
}