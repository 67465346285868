import React, { useState, useEffect } from "react"

import { makePropertyUrl } from 'utils'

export default ({
    property,
    isProjectsPage,
    customImage,
    noLink,
    transitionDelay
}) => {
    const {
        id,
        address,
        property_type,
        price_text,
        show_sold_price,
        sold_price,
        photos,
        type,
        bathrooms,
        bedrooms,
        carport_spaces,
        garage_spaces,
        off_street_spaces,
        status,
    } = property

    const totalParking = carport_spaces + garage_spaces + off_street_spaces

    const [banner, setBanner] = useState(null)

    const getPhotoUrl = () => {
        const photoKeys = photos ? Object.keys(photos) : []

        if (!photoKeys.length) {
            return null
        }

        const _photoUrl = photos[photoKeys[0]].versions.large.url

        return _photoUrl
    }

    useEffect(() => {
        const _banner = status === 5 ? 'underoffer' : status === 3 ? 'leased' : status === 2 ? 'sold' : null

        setBanner(_banner)
    }, [])

    const photoUrl = getPhotoUrl()

    const contentInner = (
        <>
            {banner &&
                <div className={`corner-banner ${banner}`} />
            }
            <div className="photo-container">
                <img className={'photo'} src={customImage || photoUrl || ''} />
                {isProjectsPage &&
                    <span>
                        View project
                    </span>
                }
            </div>
            <div className="row halves">
                <h3>
                    {address.suburb}
                </h3>
                {type === 'Commercial' ? '' :
                    <ul className="bbc">
                        <div className="beds" dangerouslySetInnerHTML={{ __html: bedrooms }} />
                        <div className="baths" dangerouslySetInnerHTML={{ __html: bathrooms }} />
                        {totalParking > 0 && <div className="cars" dangerouslySetInnerHTML={{ __html: totalParking }} />}
                    </ul>
                }
            </div>
            <div className="row">
                <p>
                    {address.street_number} {address.street_name}
                </p>
            </div>
            {!isProjectsPage &&
                <div className="row">
                    <p>
                        {property_type}
                    </p>
                    <p>
                        {status === 2 ? (show_sold_price && parseInt(show_sold_price)) ? '$' + sold_price.toLocaleString() : '' : price_text}
                    </p>
                </div>
            }
        </>
    )

    const url = makePropertyUrl(property)

    return (
        <li
            className={'property-grid-item'}
            style={{
                transitionDelay: transitionDelay ? transitionDelay + 's' : undefined
            }}
        >
            {noLink ?
                <a>
                    {contentInner}
                </a>
                :
                <a href={url}>
                    {contentInner}
                </a>
            }
        </li>
    )
}
